import * as React from 'react';
import {PositionComponent} from '../_PositionComponent';

export default () => <PositionComponent
    title={'Senior .NET Software Engineer'}
    aboutPositionTitle={'The Project'}
    aboutPosition={`We’re building a part of a brand new product for our partner, a leading fintech organization.<br/><br/>
                    You will work closely with colleagues from our partner to translate their business needs into solutions
                    that will guide them to long-term value and success. By leveraging existing vendor API’s and
                    Microsoft Azure platform, the challenge will be to rapidly deliver and iterate usable technology solutions
                    that scale to 1M+ users with an eye towards an effective feedback loop from our partner.`}
    aboutRole={`You will be involved in all the aspects of a product development starting from a feature estimation, continuing
                with the architecture design and implementation of key backend services and the user facing experience for millions
                of consumers. Additionally, you’ll have the chance to build a system on top of the Microsoft Azure cloud which will
                provide a robust foundation of the data compute and storage needed for the implementation of our real-time analytics,
                fraud detection and CRM systems.`}
    aboutYou={  [
                    `Strong CS background`,
                    `Experience with distributed systems`,
                    `.NET Core 2, TypeScript/Angular 5 is a plus`,
                    `Good English level and communication skills`,
                ]}
/>;
